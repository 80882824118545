import { ImageType } from '@/__generated__/gateway/graphql';

export const acceptedImageMimeTypes = ['image/jpeg', 'image/png'];

export const imageMimeTypeToImageType = (mimeType: string) => {
  switch (mimeType) {
    case 'image/jpg':
    case 'image/jpeg':
      return ImageType.Jpg;
    case 'image/png':
      return ImageType.Png;
  }

  return null;
};

export const maxImageSize = 5 * 1024 * 1024; //5 Mb

export const isValidMimeType = (file: File): boolean =>
  acceptedImageMimeTypes.includes(file.type);

export const isValidImageSize = (file: File): boolean =>
  file.size <= maxImageSize;
