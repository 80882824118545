import { MutationOptions } from '@apollo/client';
import { gql } from '@/__generated__/gateway';
import {
  ReadNotificationMutation,
  ReadNotificationMutationVariables,
  ReadNotificationsInput,
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables,
  SeenUserNotificationsMutation,
  SeenUserNotificationsMutationVariables
} from '@/__generated__/gateway/graphql';

export const markNotificationAsReadMutation = gql(`
  mutation ReadNotification($notificationId: ID!) {
    notification {
      readNotification(notification_id: $notificationId) {
        success
      }
    }
  }
`);

export const mutateReadNotification = (
  notificationId: string
): MutationOptions<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
> => ({
  mutation: markNotificationAsReadMutation,
  variables: { notificationId }
});

export const markNotificationsAsReadMutation = gql(`
  mutation ReadNotifications($input: ReadNotificationsInput) {
    notification {
      readNotifications(input: $input) {
        success
      }
    }
  }
`);

export const mutateReadNotifications = (
  input: ReadNotificationsInput
): MutationOptions<
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables
> => ({
  mutation: markNotificationsAsReadMutation,
  variables: { input }
});

export const seenUserNotificationsMutation = gql(`
  mutation SeenUserNotifications {
    notification {
      seenUserNotifications {
        success
      }
    }
  }
`);

export const mutateSeenUserNotifications = (): MutationOptions<
  SeenUserNotificationsMutation,
  SeenUserNotificationsMutationVariables
> => ({
  mutation: seenUserNotificationsMutation
});
