/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  mutation CreatePost($context: ContextInput, $input: CreatePostInput) {\n    community {\n      createPost(context: $context, input: $input) {\n        id\n        body\n        images\n        published_at\n        comments_count\n        own_reaction\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        context {\n          kind\n          entity\n          entity_id\n          language\n        }\n        teaser {\n          site_name\n          url\n          title\n          type\n        }\n      }\n    }\n  }\n": types.CreatePostDocument,
    "\n  mutation UpdatePost($input: UpdatePostInput) {\n    community {\n      updatePost(input: $input) {\n        id\n        body\n        images\n      }\n    }\n  }\n": types.UpdatePostDocument,
    "\n  mutation DeletePost($postId: ID!) {\n    community {\n      deletePost(post_id: $postId) {\n        success\n      }\n    }\n  }\n": types.DeletePostDocument,
    "\n  mutation CreateCommunityImageUploadOptions($input: UploadImageInput) {\n    community {\n      createImageUploadOptions(input: $input) {\n        upload_endpoint\n        file_url\n      }\n    }\n  }\n": types.CreateCommunityImageUploadOptionsDocument,
    "\n  mutation CreateProfileImageUploadOptions($input: UploadImageInput!) {\n    users {\n      createImageUploadOptions(input: $input) {\n        upload_endpoint\n        file_url\n      }\n    }\n  }\n": types.CreateProfileImageUploadOptionsDocument,
    "\n  mutation SetProfileAvatar($input: SetAvatarInput!) {\n    users {\n      setAvatar(input: $input) {\n        avatar_url\n        success\n      }\n    }\n  }\n": types.SetProfileAvatarDocument,
    "\n  mutation DeleteProfileAvatarMutation($uuid: String!) {\n    users {\n      deleteAvatar(uuid: $uuid) {\n        success\n      }\n    }\n  }\n": types.DeleteProfileAvatarMutationDocument,
    "\n  mutation CreateComment($input: CreateCommentInput) {\n    community {\n      createComment(input: $input) {\n        id\n        body\n        images\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        own_reaction\n        published_at\n        post_id\n      }\n    }\n  }\n": types.CreateCommentDocument,
    "\n  mutation UpdateComment($input: UpdateCommentInput) {\n    community {\n      updateComment(input: $input) {\n        id\n        body\n        images\n      }\n    }\n  }\n": types.UpdateCommentDocument,
    "\n  mutation DeleteComment($commentId: ID!) {\n    community {\n      deleteComment(comment_id: $commentId) {\n        success\n      }\n    }\n  }\n": types.DeleteCommentDocument,
    "\n  mutation CreateReaction($input: CreateReactionInput) {\n    community {\n      createReaction(input: $input) {\n        success\n      }\n    }\n  }\n": types.CreateReactionDocument,
    "\n  mutation DeleteReaction($input: DeleteReactionInput) {\n    community {\n      deleteReaction(input: $input) {\n        success\n      }\n    }\n  }\n": types.DeleteReactionDocument,
    "\n  mutation Follow($input: FollowInput) {\n    community {\n      follow(input: $input) {\n        success\n      }\n    }\n  }\n": types.FollowDocument,
    "\n  mutation Unfollow($input: FollowInput) {\n    community {\n      unfollow(input: $input) {\n        success\n      }\n    }\n  }\n": types.UnfollowDocument,
    "\n  mutation ReportPost($input: ReportPostInput) {\n    community {\n      reportPost(input: $input) {\n        success\n      }\n    }\n  }\n": types.ReportPostDocument,
    "\n  mutation ReportComment($input: ReportCommentInput) {\n    community {\n      reportComment(input: $input) {\n        success\n      }\n    }\n  }\n": types.ReportCommentDocument,
    "\n  mutation UpdateProfile($input: UpdateProfileInput!) {\n    users {\n      updateProfile(input: $input) {\n        success\n      }\n    }\n  }\n": types.UpdateProfileDocument,
    "\n  mutation AcceptTos($alias: String!) {\n    toc {\n      accept(alias: $alias) {\n        success\n      }\n    }\n  }\n": types.AcceptTosDocument,
    "\n  query Post($postId: ID!) {\n    community {\n      post(id: $postId) {\n        id\n        body\n        images\n        published_at\n        comments_count\n        own_reaction\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        context {\n          kind\n          entity\n          entity_id\n          language\n        }\n        teaser {\n          site_name\n          url\n          title\n          type\n        }\n      }\n    }\n  }\n": types.PostDocument,
    "\n  query Posts($cursor: String, $limit: Int, $context: ContextInput, $userId: ID) {\n    community {\n      posts(context: $context, cursor: $cursor, limit: $limit, user_id: $userId) {\n        cursor\n        limit\n        total\n        posts {\n          id\n          body\n          images\n          published_at\n          comments_count\n          own_reaction\n          author {\n            id\n            name\n            username\n            display_name\n            avatar_url\n            is_following\n          }\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          context {\n            kind\n            entity\n            entity_id\n            language\n          }\n          teaser {\n            site_name\n            url\n            title\n            type\n          }\n        }\n      }\n    }\n  }\n": types.PostsDocument,
    "\n  query Comments($postId: ID!, $limit: Int, $cursor: String) {\n    community {\n      comments(post_id: $postId, limit: $limit, cursor: $cursor) {\n        cursor\n        limit\n        comments {\n          post_id\n          body\n          id\n          images\n          author {\n            id\n            name\n            display_name\n            username\n            avatar_url\n            is_following\n          }\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          own_reaction\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          published_at\n        }\n      }\n    }\n  }\n": types.CommentsDocument,
    "\n  query Reactions($input: GetReactionsInput!) {\n    community {\n      reactions(input: $input) {\n        reactions {\n          author {\n            id\n            name\n            username\n            display_name\n            avatar_url\n            is_following\n          }\n          label\n        }\n        cursor\n        limit\n      }\n    }\n  }\n": types.ReactionsDocument,
    "\n  query Profile($userId: ID!) {\n    community {\n      profile(user_id: $userId) {\n        id\n        followers_count\n        following_count\n        user {\n          id\n          avatar_url\n          display_name\n          name\n          username\n          is_following\n          country\n          bio\n        }\n      }\n    }\n  }\n": types.ProfileDocument,
    "\n  query ProfileNameCheck($username: String!) {\n    users {\n      usernameCheck(username: $username) {\n        available\n      }\n    }\n  }\n": types.ProfileNameCheckDocument,
    "\n  mutation ReadNotification($notificationId: ID!) {\n    notification {\n      readNotification(notification_id: $notificationId) {\n        success\n      }\n    }\n  }\n": types.ReadNotificationDocument,
    "\n  mutation ReadNotifications($input: ReadNotificationsInput) {\n    notification {\n      readNotifications(input: $input) {\n        success\n      }\n    }\n  }\n": types.ReadNotificationsDocument,
    "\n  mutation SeenUserNotifications {\n    notification {\n      seenUserNotifications {\n        success\n      }\n    }\n  }\n": types.SeenUserNotificationsDocument,
    "\n  query CustomerAccessToken($storeName: String!) {\n    shopify {\n      exchangeToken(store_name: $storeName) {\n        customerAccessToken {\n          accessToken\n          expiresAt\n        }\n      }\n    }\n  }\n": types.CustomerAccessTokenDocument,
    "\n    query StarterData(\n      $input: GetNotificationsInput!\n      $alias: String!\n      $userId: ID!\n    ) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n        }\n      }\n      toc {\n        status(alias: $alias) {\n          success\n        }\n      }\n      community {\n        profile(user_id: $userId) {\n          followers_count\n          following_count\n          user {\n            id\n            bio\n            username\n            display_name\n            country\n            avatar_url\n          }\n        }\n      }\n    }\n  ": types.StarterDataDocument,
    "\n  query Notifications($input: GetNotificationsInput!) {\n    notification {\n      notifications(input: $input) {\n        unread\n        unseen\n        total\n        cursor\n        notifications {\n          id\n          message\n          read_at\n          created_at\n          actor {\n            display_name\n            avatar_url\n          }\n          sourceable_type\n          sourceable_id\n          subject {\n            ... on Post {\n              id\n            }\n            ... on Comment {\n              id\n              post_id\n            }\n          }\n        }\n      }\n    }\n  }\n": types.NotificationsDocument,
    "\n    query countryConfigs {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storefront {\n            endpoint\n            name\n            publicAccessToken\n          }\n          storeEndpoint\n        }\n      }\n    }\n  ": types.CountryConfigsDocument,
    "\n  query customerContent {\n    customerContent {\n      content {\n        ... on Pattern {\n          id\n          sku\n          url\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.CustomerContentDocument,
    "\n    query PostCounters($galleryContext: ContextInput, $qaContext: ContextInput) {\n      community {\n        gallery: posts(context: $galleryContext) {\n          total\n        }\n        qa: posts(context: $qaContext) {\n          total\n        }\n      }\n    }\n  ": types.PostCountersDocument,
    "\n  query loyalty {\n    loyalty {\n      points {\n        cursor\n        limit\n        points {\n          amount\n          created_at\n          type\n          description\n        }\n      }\n    }\n  }\n": types.LoyaltyDocument,
    "\n    query PatternBySku(\n      $sku: String!\n      $urlVersion: String\n      $language: String\n      $contactUUID: String\n      $countryCode: String\n    ) {\n      patternBySku(\n        sku: $sku\n        urlVersion: $urlVersion\n        language: $language\n        contactUUID: $contactUUID\n        countryCode: $countryCode\n      ) {\n        url\n        sku\n        id\n        createdAt\n        updatedAt\n      }\n    }\n  ": types.PatternBySkuDocument,
    "\n    query GetCountriesDataQuery {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storeEndpoint\n        }\n        legacyStore {\n          id\n          name\n          country\n          country_switcher_allowed\n          is_international\n          language\n          url\n          currency\n        }\n      }\n    }\n  ": types.GetCountriesDataQueryDocument,
    "\n  query BingoId($countryConfigInput: CountryConfigInput) {\n    countryConfigs(countryConfigInput: $countryConfigInput) {\n      bingoId\n    }\n  }\n": types.BingoIdDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreatePost($context: ContextInput, $input: CreatePostInput) {\n    community {\n      createPost(context: $context, input: $input) {\n        id\n        body\n        images\n        published_at\n        comments_count\n        own_reaction\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        context {\n          kind\n          entity\n          entity_id\n          language\n        }\n        teaser {\n          site_name\n          url\n          title\n          type\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePost($context: ContextInput, $input: CreatePostInput) {\n    community {\n      createPost(context: $context, input: $input) {\n        id\n        body\n        images\n        published_at\n        comments_count\n        own_reaction\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        context {\n          kind\n          entity\n          entity_id\n          language\n        }\n        teaser {\n          site_name\n          url\n          title\n          type\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdatePost($input: UpdatePostInput) {\n    community {\n      updatePost(input: $input) {\n        id\n        body\n        images\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdatePost($input: UpdatePostInput) {\n    community {\n      updatePost(input: $input) {\n        id\n        body\n        images\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeletePost($postId: ID!) {\n    community {\n      deletePost(post_id: $postId) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeletePost($postId: ID!) {\n    community {\n      deletePost(post_id: $postId) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateCommunityImageUploadOptions($input: UploadImageInput) {\n    community {\n      createImageUploadOptions(input: $input) {\n        upload_endpoint\n        file_url\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCommunityImageUploadOptions($input: UploadImageInput) {\n    community {\n      createImageUploadOptions(input: $input) {\n        upload_endpoint\n        file_url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateProfileImageUploadOptions($input: UploadImageInput!) {\n    users {\n      createImageUploadOptions(input: $input) {\n        upload_endpoint\n        file_url\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateProfileImageUploadOptions($input: UploadImageInput!) {\n    users {\n      createImageUploadOptions(input: $input) {\n        upload_endpoint\n        file_url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetProfileAvatar($input: SetAvatarInput!) {\n    users {\n      setAvatar(input: $input) {\n        avatar_url\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetProfileAvatar($input: SetAvatarInput!) {\n    users {\n      setAvatar(input: $input) {\n        avatar_url\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteProfileAvatarMutation($uuid: String!) {\n    users {\n      deleteAvatar(uuid: $uuid) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteProfileAvatarMutation($uuid: String!) {\n    users {\n      deleteAvatar(uuid: $uuid) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateComment($input: CreateCommentInput) {\n    community {\n      createComment(input: $input) {\n        id\n        body\n        images\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        own_reaction\n        published_at\n        post_id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateComment($input: CreateCommentInput) {\n    community {\n      createComment(input: $input) {\n        id\n        body\n        images\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        own_reaction\n        published_at\n        post_id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateComment($input: UpdateCommentInput) {\n    community {\n      updateComment(input: $input) {\n        id\n        body\n        images\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateComment($input: UpdateCommentInput) {\n    community {\n      updateComment(input: $input) {\n        id\n        body\n        images\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteComment($commentId: ID!) {\n    community {\n      deleteComment(comment_id: $commentId) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteComment($commentId: ID!) {\n    community {\n      deleteComment(comment_id: $commentId) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateReaction($input: CreateReactionInput) {\n    community {\n      createReaction(input: $input) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateReaction($input: CreateReactionInput) {\n    community {\n      createReaction(input: $input) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteReaction($input: DeleteReactionInput) {\n    community {\n      deleteReaction(input: $input) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteReaction($input: DeleteReactionInput) {\n    community {\n      deleteReaction(input: $input) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Follow($input: FollowInput) {\n    community {\n      follow(input: $input) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Follow($input: FollowInput) {\n    community {\n      follow(input: $input) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Unfollow($input: FollowInput) {\n    community {\n      unfollow(input: $input) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Unfollow($input: FollowInput) {\n    community {\n      unfollow(input: $input) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ReportPost($input: ReportPostInput) {\n    community {\n      reportPost(input: $input) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ReportPost($input: ReportPostInput) {\n    community {\n      reportPost(input: $input) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ReportComment($input: ReportCommentInput) {\n    community {\n      reportComment(input: $input) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ReportComment($input: ReportCommentInput) {\n    community {\n      reportComment(input: $input) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateProfile($input: UpdateProfileInput!) {\n    users {\n      updateProfile(input: $input) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProfile($input: UpdateProfileInput!) {\n    users {\n      updateProfile(input: $input) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AcceptTos($alias: String!) {\n    toc {\n      accept(alias: $alias) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptTos($alias: String!) {\n    toc {\n      accept(alias: $alias) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Post($postId: ID!) {\n    community {\n      post(id: $postId) {\n        id\n        body\n        images\n        published_at\n        comments_count\n        own_reaction\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        context {\n          kind\n          entity\n          entity_id\n          language\n        }\n        teaser {\n          site_name\n          url\n          title\n          type\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Post($postId: ID!) {\n    community {\n      post(id: $postId) {\n        id\n        body\n        images\n        published_at\n        comments_count\n        own_reaction\n        author {\n          id\n          name\n          username\n          display_name\n          avatar_url\n          is_following\n        }\n        reaction_counts {\n          love\n          in_love\n          smile\n          cool\n          haha\n          wow\n        }\n        context {\n          kind\n          entity\n          entity_id\n          language\n        }\n        teaser {\n          site_name\n          url\n          title\n          type\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Posts($cursor: String, $limit: Int, $context: ContextInput, $userId: ID) {\n    community {\n      posts(context: $context, cursor: $cursor, limit: $limit, user_id: $userId) {\n        cursor\n        limit\n        total\n        posts {\n          id\n          body\n          images\n          published_at\n          comments_count\n          own_reaction\n          author {\n            id\n            name\n            username\n            display_name\n            avatar_url\n            is_following\n          }\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          context {\n            kind\n            entity\n            entity_id\n            language\n          }\n          teaser {\n            site_name\n            url\n            title\n            type\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Posts($cursor: String, $limit: Int, $context: ContextInput, $userId: ID) {\n    community {\n      posts(context: $context, cursor: $cursor, limit: $limit, user_id: $userId) {\n        cursor\n        limit\n        total\n        posts {\n          id\n          body\n          images\n          published_at\n          comments_count\n          own_reaction\n          author {\n            id\n            name\n            username\n            display_name\n            avatar_url\n            is_following\n          }\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          context {\n            kind\n            entity\n            entity_id\n            language\n          }\n          teaser {\n            site_name\n            url\n            title\n            type\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Comments($postId: ID!, $limit: Int, $cursor: String) {\n    community {\n      comments(post_id: $postId, limit: $limit, cursor: $cursor) {\n        cursor\n        limit\n        comments {\n          post_id\n          body\n          id\n          images\n          author {\n            id\n            name\n            display_name\n            username\n            avatar_url\n            is_following\n          }\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          own_reaction\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          published_at\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Comments($postId: ID!, $limit: Int, $cursor: String) {\n    community {\n      comments(post_id: $postId, limit: $limit, cursor: $cursor) {\n        cursor\n        limit\n        comments {\n          post_id\n          body\n          id\n          images\n          author {\n            id\n            name\n            display_name\n            username\n            avatar_url\n            is_following\n          }\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          own_reaction\n          reaction_counts {\n            love\n            in_love\n            smile\n            cool\n            haha\n            wow\n          }\n          published_at\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Reactions($input: GetReactionsInput!) {\n    community {\n      reactions(input: $input) {\n        reactions {\n          author {\n            id\n            name\n            username\n            display_name\n            avatar_url\n            is_following\n          }\n          label\n        }\n        cursor\n        limit\n      }\n    }\n  }\n"): (typeof documents)["\n  query Reactions($input: GetReactionsInput!) {\n    community {\n      reactions(input: $input) {\n        reactions {\n          author {\n            id\n            name\n            username\n            display_name\n            avatar_url\n            is_following\n          }\n          label\n        }\n        cursor\n        limit\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Profile($userId: ID!) {\n    community {\n      profile(user_id: $userId) {\n        id\n        followers_count\n        following_count\n        user {\n          id\n          avatar_url\n          display_name\n          name\n          username\n          is_following\n          country\n          bio\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Profile($userId: ID!) {\n    community {\n      profile(user_id: $userId) {\n        id\n        followers_count\n        following_count\n        user {\n          id\n          avatar_url\n          display_name\n          name\n          username\n          is_following\n          country\n          bio\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ProfileNameCheck($username: String!) {\n    users {\n      usernameCheck(username: $username) {\n        available\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProfileNameCheck($username: String!) {\n    users {\n      usernameCheck(username: $username) {\n        available\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ReadNotification($notificationId: ID!) {\n    notification {\n      readNotification(notification_id: $notificationId) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ReadNotification($notificationId: ID!) {\n    notification {\n      readNotification(notification_id: $notificationId) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ReadNotifications($input: ReadNotificationsInput) {\n    notification {\n      readNotifications(input: $input) {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ReadNotifications($input: ReadNotificationsInput) {\n    notification {\n      readNotifications(input: $input) {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SeenUserNotifications {\n    notification {\n      seenUserNotifications {\n        success\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SeenUserNotifications {\n    notification {\n      seenUserNotifications {\n        success\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CustomerAccessToken($storeName: String!) {\n    shopify {\n      exchangeToken(store_name: $storeName) {\n        customerAccessToken {\n          accessToken\n          expiresAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CustomerAccessToken($storeName: String!) {\n    shopify {\n      exchangeToken(store_name: $storeName) {\n        customerAccessToken {\n          accessToken\n          expiresAt\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StarterData(\n      $input: GetNotificationsInput!\n      $alias: String!\n      $userId: ID!\n    ) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n        }\n      }\n      toc {\n        status(alias: $alias) {\n          success\n        }\n      }\n      community {\n        profile(user_id: $userId) {\n          followers_count\n          following_count\n          user {\n            id\n            bio\n            username\n            display_name\n            country\n            avatar_url\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StarterData(\n      $input: GetNotificationsInput!\n      $alias: String!\n      $userId: ID!\n    ) {\n      notification {\n        notifications(input: $input) {\n          unread\n          unseen\n        }\n      }\n      toc {\n        status(alias: $alias) {\n          success\n        }\n      }\n      community {\n        profile(user_id: $userId) {\n          followers_count\n          following_count\n          user {\n            id\n            bio\n            username\n            display_name\n            country\n            avatar_url\n          }\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Notifications($input: GetNotificationsInput!) {\n    notification {\n      notifications(input: $input) {\n        unread\n        unseen\n        total\n        cursor\n        notifications {\n          id\n          message\n          read_at\n          created_at\n          actor {\n            display_name\n            avatar_url\n          }\n          sourceable_type\n          sourceable_id\n          subject {\n            ... on Post {\n              id\n            }\n            ... on Comment {\n              id\n              post_id\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Notifications($input: GetNotificationsInput!) {\n    notification {\n      notifications(input: $input) {\n        unread\n        unseen\n        total\n        cursor\n        notifications {\n          id\n          message\n          read_at\n          created_at\n          actor {\n            display_name\n            avatar_url\n          }\n          sourceable_type\n          sourceable_id\n          subject {\n            ... on Post {\n              id\n            }\n            ... on Comment {\n              id\n              post_id\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query countryConfigs {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storefront {\n            endpoint\n            name\n            publicAccessToken\n          }\n          storeEndpoint\n        }\n      }\n    }\n  "): (typeof documents)["\n    query countryConfigs {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storefront {\n            endpoint\n            name\n            publicAccessToken\n          }\n          storeEndpoint\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query customerContent {\n    customerContent {\n      content {\n        ... on Pattern {\n          id\n          sku\n          url\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query customerContent {\n    customerContent {\n      content {\n        ... on Pattern {\n          id\n          sku\n          url\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query PostCounters($galleryContext: ContextInput, $qaContext: ContextInput) {\n      community {\n        gallery: posts(context: $galleryContext) {\n          total\n        }\n        qa: posts(context: $qaContext) {\n          total\n        }\n      }\n    }\n  "): (typeof documents)["\n    query PostCounters($galleryContext: ContextInput, $qaContext: ContextInput) {\n      community {\n        gallery: posts(context: $galleryContext) {\n          total\n        }\n        qa: posts(context: $qaContext) {\n          total\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query loyalty {\n    loyalty {\n      points {\n        cursor\n        limit\n        points {\n          amount\n          created_at\n          type\n          description\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query loyalty {\n    loyalty {\n      points {\n        cursor\n        limit\n        points {\n          amount\n          created_at\n          type\n          description\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query PatternBySku(\n      $sku: String!\n      $urlVersion: String\n      $language: String\n      $contactUUID: String\n      $countryCode: String\n    ) {\n      patternBySku(\n        sku: $sku\n        urlVersion: $urlVersion\n        language: $language\n        contactUUID: $contactUUID\n        countryCode: $countryCode\n      ) {\n        url\n        sku\n        id\n        createdAt\n        updatedAt\n      }\n    }\n  "): (typeof documents)["\n    query PatternBySku(\n      $sku: String!\n      $urlVersion: String\n      $language: String\n      $contactUUID: String\n      $countryCode: String\n    ) {\n      patternBySku(\n        sku: $sku\n        urlVersion: $urlVersion\n        language: $language\n        contactUUID: $contactUUID\n        countryCode: $countryCode\n      ) {\n        url\n        sku\n        id\n        createdAt\n        updatedAt\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetCountriesDataQuery {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storeEndpoint\n        }\n        legacyStore {\n          id\n          name\n          country\n          country_switcher_allowed\n          is_international\n          language\n          url\n          currency\n        }\n      }\n    }\n  "): (typeof documents)["\n    query GetCountriesDataQuery {\n      countryConfigs {\n        countryCode\n        shopifyConfig {\n          storeEndpoint\n        }\n        legacyStore {\n          id\n          name\n          country\n          country_switcher_allowed\n          is_international\n          language\n          url\n          currency\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query BingoId($countryConfigInput: CountryConfigInput) {\n    countryConfigs(countryConfigInput: $countryConfigInput) {\n      bingoId\n    }\n  }\n"): (typeof documents)["\n  query BingoId($countryConfigInput: CountryConfigInput) {\n    countryConfigs(countryConfigInput: $countryConfigInput) {\n      bingoId\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;