import { defineStore } from 'pinia';
import { formatMoney } from '@/shared/utils/shopify/money-formatter';

import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock';

export const useConfigStore = defineStore('config', {
  state: () => ({
    countryCode: null,
    graphqlHost: null,
    language: 'en',
    scrollableElements: [],
    lastSearch: '',
    minSearch: 3,
    pointsMultiplier: 0
  }),
  getters: {
    calculatedPoints() {
      return (value) => {
        // calculates points based on the points multiplier
        // takes product value in cents and returns the points calculated
        // we always round up to the next integer
        return Math.round((value * this.pointsMultiplier) / 100);
      };
    },
    formattedPrice() {
      const format = window.Hobbii?.moneyFormat;
      return (value) => formatMoney(value, format);
    },
    isBodyScrollLocked() {
      return this.scrollableElements.length > 0;
    }
  },
  actions: {
    clearAllBodyScrollLocks() {
      clearBodyLocks();
    },
    lockBodyScroll(selector) {
      const element = selector ? document.querySelector(selector) : null;

      // Allow for elements with position:sticky with overflowType 'clip'
      lock(element, { overflowType: 'clip' });

      if (element) {
        this.scrollableElements.push(element);
      }
    },
    allowBodyScroll() {
      this.scrollableElements.forEach((el) => {
        unlock(el);
      });
      this.scrollableElements = [];
    }
  }
});
