const DAY_IN_MS = 86400000;
const HOUR_IN_MS = 3600000;
const MINUTE_IN_MS = 60000;
const SECOND_IN_MS = 1000;

export const locale =
  window.Shopify.locale && window.Shopify.country
    ? `${window.Shopify.locale}-${window.Shopify.country}`
    : `en-UK`; // fallback to "euro" english formatting

export type RelativeTimeValue = {
  delimiter: number;
  updateInterval?: number;
};

// Note: This may NOT be empty, and order is important: Largest to smallest!
export const ORDERED_TIME_FORMAT_UNIT = new Map<
  Intl.RelativeTimeFormatUnit,
  RelativeTimeValue
>([
  ['year', { delimiter: DAY_IN_MS * 365 }],
  ['month', { delimiter: DAY_IN_MS * 31 }], // not as precise, but will show e.g. "4 weeks ago" until hit
  ['week', { delimiter: DAY_IN_MS * 7 }],
  ['day', { delimiter: DAY_IN_MS, updateInterval: HOUR_IN_MS }],
  ['hour', { delimiter: HOUR_IN_MS, updateInterval: MINUTE_IN_MS * 10 }],
  ['minute', { delimiter: MINUTE_IN_MS, updateInterval: MINUTE_IN_MS }],
  ['second', { delimiter: SECOND_IN_MS, updateInterval: MINUTE_IN_MS }]
]);

export const directionalFloor = (num: number) =>
  num > 0 ? Math.floor(num) : Math.ceil(num);

export const isValidDate = (date: unknown) =>
  date instanceof Date && !isNaN(date.getTime());

export const isYesterday = (date: Date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return date.toDateString() === yesterday.toDateString();
};

export const isTomorrow = (date: Date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() + 1);

  return date.toDateString() === yesterday.toDateString();
};
