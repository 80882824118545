import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import {
  LanguageType,
  NotificationsQuery,
  NotificationsQueryVariables,
  ReadNotificationMutation,
  ReadNotificationsMutation,
  SeenUserNotificationsMutation
} from '@/__generated__/gateway/graphql';
import { notificationsQuery } from '@/shared/graphql/gateway/queries';
import {
  markNotificationAsReadMutation,
  markNotificationsAsReadMutation,
  seenUserNotificationsMutation
} from '@/shared/graphql/gateway/mutations';
import { ApolloCache, FetchResult } from '@apollo/client';

const DEFAULT_NOTIFICATION_LIMIT = 20;

type SeenUserNotificationsResult = Omit<
  FetchResult<SeenUserNotificationsMutation>,
  'context'
>;
type ReadNotificationResult = Omit<
  FetchResult<ReadNotificationMutation>,
  'context'
>;
type ReadNotificationsResult = Omit<
  FetchResult<ReadNotificationsMutation>,
  'context'
>;

export const useGatewayNotifications = (
  language: LanguageType,
  limit: number = DEFAULT_NOTIFICATION_LIMIT
) => {
  // querying

  const queryVariables: NotificationsQueryVariables = {
    input: {
      language,
      paginate: {
        limit
      }
    }
  };

  const {
    loading,
    result,
    error: queryError,
    refetch,
    fetchMore
  } = useQuery(notificationsQuery, queryVariables, { clientId: 'gateway' });

  const notifications = computed(
    () => result.value?.notification?.notifications.notifications ?? []
  );

  type Notification = (typeof notifications.value)[0];

  const unreadIds = computed(() =>
    notifications.value.filter((n) => !n.read_at).map((n) => n.id)
  );

  const unseen = computed(
    () => result.value?.notification?.notifications.unseen ?? 0
  );

  const unseenMaxed = computed(() =>
    unseen.value > 99 ? '99+' : unseen.value
  );

  const loadMoreCursor = computed(
    () => result.value?.notification?.notifications?.cursor ?? null
  );
  const hasMore = computed(() => !!loadMoreCursor.value);

  const loadMore = () => {
    if (loadMoreCursor.value) {
      fetchMore({
        variables: {
          input: {
            ...queryVariables.input,
            paginate: {
              ...queryVariables.input.paginate,
              cursor: loadMoreCursor.value
            }
          }
        },
        updateQuery: appendToExistingData
      });
    }
  };

  // mutating

  const {
    mutate: markNotificationsAsSeen,
    loading: markingNotificationsAsSeen,
    error: markingNotificationsAsSeenError
  } = useMutation(seenUserNotificationsMutation, { clientId: 'gateway' });

  const {
    mutate: markNotificationAsRead,
    loading: markingNotificationAsRead,
    error: markingNotificationAsReadError
  } = useMutation(markNotificationAsReadMutation, { clientId: 'gateway' });

  const {
    mutate: markNotificationsAsRead,
    loading: markingNotificationsAsRead,
    error: markingNotificationsAsReadError
  } = useMutation(markNotificationsAsReadMutation, { clientId: 'gateway' });

  const markAsRead = async (input: string | string[]) => {
    if (Array.isArray(input)) {
      await markNotificationsAsRead(
        {
          input: {
            notifications: input
          }
        },
        {
          update: cacheUpdateReadStatus(input)
        }
      );
    } else {
      await markNotificationAsRead(
        {
          notificationId: input
        },
        {
          update: cacheUpdateReadStatus(input)
        }
      );
    }
  };

  const markAllAsSeen = async () => {
    await markNotificationsAsSeen(
      {},
      {
        update: cacheUpdateSeenStatus
      }
    );
  };

  // utilizes

  // NOTE: This could be in a helper class, but kept here as the API types are a bit screwed (some props are not optional... for graphql?)
  const generateNotificationUrl = (notification: Notification) => {
    if (notification.subject.__typename == 'Post') {
      return notification.sourceable_type == 'comment'
        ? `/a/community/posts/${notification.subject.id}/comments/${notification.sourceable_id}`
        : `/a/community/posts/${notification.subject.id}`;
    } else if (notification.subject.__typename == 'Comment') {
      return `/a/community/posts/${notification.subject.post_id}/comments/${notification.subject.id}`;
    }

    console.error('could not generate url from notification', notification);
    return '#';
  };

  const mutating = computed(
    () =>
      markingNotificationsAsSeen.value ||
      markingNotificationAsRead.value ||
      markingNotificationsAsRead.value
  );

  const processing = computed(() => loading.value || mutating.value);

  const mutateError = computed(
    () =>
      markingNotificationAsReadError.value ??
      markingNotificationsAsReadError.value ??
      markingNotificationsAsSeenError.value
  );

  const error = computed(() => queryError.value ?? mutateError.value);

  return {
    loading,
    mutating,
    processing,
    error,
    notifications,
    unseen,
    unseenMaxed,
    unreadIds,
    hasMore,
    markAllAsSeen,
    markAsRead,
    generateNotificationUrl,
    loadMore,
    refetch
  };
};

/**
 * Appends a fetchMore result to existing data
 * @param previousResult current data
 * @param options containing fetchMoreResult
 */
const appendToExistingData = (
  previousResult: NotificationsQuery,
  {
    fetchMoreResult
  }: {
    fetchMoreResult?: NotificationsQuery;
  }
) => {
  // No new comments, return previous
  if (
    !previousResult?.notification?.notifications?.notifications ||
    !fetchMoreResult?.notification?.notifications?.notifications
  ) {
    return previousResult;
  }

  return {
    ...fetchMoreResult,
    notification: {
      ...fetchMoreResult.notification,
      notifications: {
        ...fetchMoreResult.notification.notifications,
        notifications: [
          ...previousResult.notification.notifications.notifications,
          ...fetchMoreResult.notification.notifications.notifications
        ]
      }
    }
  };
};

const cacheUpdateSeenStatus = <T>(
  cache: ApolloCache<T>,
  result: SeenUserNotificationsResult
) => {
  if (!result.data?.notification?.seenUserNotifications.success) {
    return;
  }

  cache.modify({
    id: cache.identify({
      __typename: 'NotificationsResponse'
    }),
    fields: {
      unseen() {
        return 0;
      }
    },
    broadcast: false
  });
};

const cacheUpdateReadStatus =
  (input: string | string[]) =>
  <T>(
    cache: ApolloCache<T>,
    result: ReadNotificationResult | ReadNotificationsResult
  ) => {
    const notificationWrapper = result.data?.notification;

    if (
      !notificationWrapper ||
      ('readNotification' in notificationWrapper &&
        !notificationWrapper.readNotification?.success) ||
      ('readNotifications' in notificationWrapper &&
        !notificationWrapper.readNotifications?.success)
    ) {
      return;
    }

    const ids = Array.isArray(input) ? input : [input];

    cache.modify({
      id: cache.identify({
        __typename: 'NotificationsResponse'
      }),
      fields: {
        unread(cachedValue) {
          return cachedValue - ids.length;
        }
      },
      broadcast: false
    });

    const readDateString = new Date().toISOString();

    for (const id of ids) {
      cache.modify({
        id: cache.identify({
          __typename: 'Notification',
          id
        }),
        fields: {
          read_at() {
            return readDateString;
          }
        },
        broadcast: false
      });
    }
  };
