export const getCookie = (name: string) => {
  const target = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(target) === 0) {
      return c.substring(target.length, c.length);
    }
  }
  return null;
};

export const setCookie = (
  name: string,
  value: string,
  daysUntilExpire: number
) => {
  const date = new Date();
  date.setTime(date.getTime() + daysUntilExpire * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + '; ' + expires + '; path=/';
};
