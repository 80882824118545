import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { getToken } from '../utils/get-token';
import apolloClientInit from '../utils/apollo-client';
import { getCookie } from '@/shared/utils/cookie';
import { userCookies } from '@/shared/constants/cookies';
import { useConfigStore } from '../pinia/modules/config';
import { useUserStore } from '../pinia/modules/user';
import { useCartStore } from '../pinia/modules/cart';
import { profileQuery } from '@/shared/graphql/gateway/community/queries';

const initHobbiiStarter = async () => {
  const alreadyInitialized = !!window.hobbiiStore;

  if (!alreadyInitialized) {
    window.hobbiiStore = createPinia();

    // Set active Pinia instance.
    // Pinia needs access to an app to be available everywhere.
    createApp().use(window.hobbiiStore);

    const graphqlHost = import.meta.env.VITE_HOBBII_GATEWAY;
    const apolloClient = apolloClientInit({ graphqlHost });

    // TODO: Get rid of this plugin approach, it hides circular deps & using `this` in JS is a mine field.
    window.hobbiiStore.use(() => ({ apolloClient })); // Apollo Client plugin

    const configStore = useConfigStore();
    const userStore = useUserStore();
    if (window.Hobbii.hobbiiStoreData) {
      const { countryCode, language, isLogged, pointsMultiplier } =
        window.Hobbii.hobbiiStoreData;

      configStore.$patch({
        countryCode,
        language,
        graphqlHost,
        pointsMultiplier
      });

      if (isLogged) {
        const token = getCookie(userCookies.C_ID);
        if (token) {
          userStore.setCustomer(token);
        } else {
          let token = await getToken();
          if (!token) {
            token = await getToken(true);
          }

          userStore.setCustomer(token);
        }

        if (userStore.customer?.uuid) {
          try {
            const { data } = await apolloClient.query({
              query: profileQuery,
              variables: {
                userId: userStore.customer.uuid
              }
            });
            if (data?.community?.profile?.user) {
              userStore.setCommunityProfile(data.community.profile.user);
            }
          } catch (err) {
            console.error('Failed to get starter data', err);
          }
        }
      } else {
        userStore.cleanCustomer();
      }
    }

    const cartStore = useCartStore();
    cartStore.getCart();
  }
};

export { initHobbiiStarter };
