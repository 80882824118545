import { createApp, Component, computed, App } from 'vue';
import { initHobbiiStarter } from '@/shared/instances/hobbii-starter';
import apolloClientInit from '@/shared/utils/apollo-client';
import { ApolloClients } from '@vue/apollo-composable';
import { useUserStore } from '@/shared/pinia/modules/user';
import { openLoginModal } from '@/shared/instances/login-modal';
import { setupI18n } from '@/shared/utils/i18n';

export const mountComponentAsApp = async (
  component: Component,
  target: Element | string,
  appDecorator: ((app: App) => void | Promise<void>) | null,
  props?: Record<string, unknown>
) => {
  const targetElement =
    typeof target === 'string' ? document.querySelector(target) : target;
  if (!targetElement) {
    console.error(`Element "${target}" not found.`);
    return;
  }
  // we need to ensure Pinia is instantiated - there has to be a better way.
  await initHobbiiStarter();

  if (!window.hobbiiStore) {
    console.error('window.hobbiiStore not initialized.');
    return;
  }

  // TODO: expose storefront apollo client?

  const graphqlHost = import.meta.env.VITE_HOBBII_GATEWAY;
  const gatewayApolloClient = apolloClientInit({ graphqlHost });

  const userStore = useUserStore();
  const profile = computed(() => userStore.communityProfile);

  const i18n = await setupI18n(window.Shopify.locale);

  const app = createApp(component, props)
    .use(window.hobbiiStore)
    .use(i18n)
    .provide('profile', profile)
    .provide('initLogin', openLoginModal)
    .provide(ApolloClients, {
      gateway: gatewayApolloClient
    });

  await appDecorator?.(app);

  app.mount(targetElement);
};
