import { createI18n, I18n, PluralizationRule } from 'vue-i18n';

/**
 * Set up global i18n instance for use with Vue
 * https://vue-i18n.intlify.dev/guide/advanced/typescript
 */

// Function to load locale messages dynamically
async function loadLocaleMessages(locale: string) {
  try {
    let messages;
    switch (locale) {
      case 'da':
        messages = await import('@/locales/da.json');
        break;

      case 'de':
        messages = await import('@/locales/de.json');
        break;

      case 'en':
        messages = await import('@/locales/en.json');
        break;

      case 'es':
        messages = await import('@/locales/es.json');
        break;

      case 'fi':
        messages = await import('@/locales/fi.json');
        break;

      case 'fr':
        messages = await import('@/locales/fr.json');
        break;

      case 'it':
        messages = await import('@/locales/it.json');
        break;

      case 'nl':
        messages = await import('@/locales/nl.json');
        break;

      case 'no':
        messages = await import('@/locales/no.json');
        break;

      case 'pl':
        messages = await import('@/locales/pl.json');
        break;

      case 'se':
        messages = await import('@/locales/se.json');
        break;

      default:
        messages = await import('@/locales/en.json');
        console.error(
          `No locale "${locale}" is defined in project, fallback to "en"`
        );
    }

    return messages.default || messages;
  } catch (error) {
    console.error(`Failed to load locale messages for ${locale}`, error);
    throw error;
  }
}

export type MessageSchema = Awaited<ReturnType<typeof loadLocaleMessages>>;

let i18nInstance: I18n<MessageSchema> | null = null;

export async function setupI18n(locale: string) {
  if (!i18nInstance) {
    const messages = await loadLocaleMessages(locale);

    /**
     * @param choice the number to determine the plural form
     * @param choicesLength the number of different plural forms provided in the translation message
     */
    const polishRules: PluralizationRule = (choice, choicesLength) => {
      // https://www.unicode.org/cldr/charts/45/supplemental/language_plural_rules.html#pl
      /**
       * Formats:
       * "{n} singular | {n} genitive singular | {n} genitive plural"
       * "None case | {n} singular | {n} genitive singular | {n} genitive plural"
       */

      // yield warning if incorrect choicesLength.
      if (choicesLength < 3 || 4 < choicesLength) {
        console.warn(
          `Expected 3-4 plural forms for Polish, but less than ${choicesLength} forms were provided. This may produce incorrect grammar.`
        );
      }

      const hasNoneFormat = choicesLength > 3;

      if (choice === 0) {
        return hasNoneFormat ? 0 : 2;
      }

      if (choice === 1) {
        return hasNoneFormat ? 1 : 0;
      }

      const mod10 = choice % 10;
      const inTeens = choice > 10 && choice < 20;

      if (!inTeens && mod10 >= 2 && mod10 <= 4) {
        return hasNoneFormat ? 2 : 1;
      }

      return hasNoneFormat ? 3 : 2;
    };

    i18nInstance = createI18n({
      legacy: false, // Ensure you set this to false for Vue 3
      locale,
      pluralRules: {
        pl: polishRules
      },
      fallbackLocale: 'en',
      globalInjection: false, // Do not expose $t() etc, forcing composition API
      messages: {
        // Only setup a single locale. We currently only support one language per market, so no need to expose multiple locales
        [locale]: messages
      }
    });
  }

  return i18nInstance;
}
