import Bugsnag from '@bugsnag/js';

const options = {
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  releaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE,
  appVersion: import.meta.env.VITE_BUGSNAG_APP_VERSION
};

if (options.apiKey && options.releaseStage && options.appVersion) {
  Bugsnag.start(options);
}

window._bugsnag = Bugsnag;
