import { CommunityAuthor } from '@/__generated__/gateway/graphql';
import { ContentModel } from '@/components/community/utils/types';
import { isValidMimeType } from '@/components/community/utils/validation';

export const profileUrlPrefix = `${location.protocol}//${location.host}/a/community/`;

export const generateProfileUrl = (
  usernameOrAuthor: CommunityAuthor | string
) =>
  `${profileUrlPrefix}@${
    typeof usernameOrAuthor === 'string'
      ? usernameOrAuthor
      : usernameOrAuthor.username
  }`;

export const addValidFilesToModel = (
  model: ContentModel,
  files: FileList,
  override = false
) => {
  const validNewFiles = Array.from(files)
    // NOTE: file size validation is visualized to the user,
    // so do not filter out files exceeding the size limit.
    .filter(isValidMimeType)
    .map((file) => ({
      file,
      previewUrl: URL.createObjectURL(file)
    }));

  if (override && validNewFiles.length === 1) {
    model.images = validNewFiles;
  } else {
    model.images.push(...validNewFiles);
  }
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
