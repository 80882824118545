import GlobalKeyDownDirective from '../directives/globalKeyDown';
import TocVerification from '../components/Toc/TocVerification.vue';
import { mountComponentAsApp } from '@/entrypoints/shared/hobbii-app-mounter';

export const initTocVerification = async () => {
  const mountId = '#toc-verification';

  await mountComponentAsApp(TocVerification, mountId, (app) => {
    // TODO: remove global-keydown directive once all components are self contained.
    app.directive('global-keydown', GlobalKeyDownDirective);
  });
};
